import { TooltipType, TooltipPriorityType } from './Tooltip';

type StyleMapType = Record<TooltipPriorityType, Record<TooltipType, string>>;
type IconNameMap = Record<TooltipType, string>;

export const tooltipTextColorMap: StyleMapType = {
  high: {
    success: 'text-white',
    warning: 'text-gray-900',
    error: 'text-white',
    information: 'text-white',
    accent: 'text-white',
    primary: 'text-white',
    secondary: 'text-gray-600',
    tertiary: 'text-gray-600',
  },
  low: {
    success: 'text-content-primary',
    warning: 'text-gray-900',
    error: 'text-gray-900',
    information: 'text-gray-900',
    accent: 'text-white',
    primary: 'text-white',
    secondary: 'text-gray-600',
    tertiary: 'text-gray-600',
  },
};
export const tooltipSubTextColorMap: StyleMapType = {
  high: {
    success: 'text-white',
    warning: 'text-gray-600',
    error: 'text-white',
    information: 'text-white',
    accent: 'text-white',
    primary: 'text-white',
    secondary: 'text-gray-600',
    tertiary: 'text-gray-600',
  },
  low: {
    success: 'text-gray-600',
    warning: 'text-gray-600',
    error: 'text-gray-600',
    information: 'text-gray-600',
    accent: 'text-white',
    primary: 'text-white',
    secondary: 'text-gray-600',
    tertiary: 'text-gray-600',
  },
};

export const tooltipIconMap: IconNameMap = {
  success: 'check-circle',
  warning: 'exclamation-triangle',
  error: 'exclamation-circle',
  information: 'info-circle',
  accent: 'spinner-third',
  primary: 'envelope',
  secondary: 'envelope',
  tertiary: 'envelope',
};
