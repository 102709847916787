import Helmet from 'react-helmet';

interface ISEOProps {
  title?: string;
  description?: string;
}
const SEO = ({ title, description }: ISEOProps) => {
  return (
    <Helmet title={title ? `${title} | Movableink` : 'Movableink'}>
      {description && <meta name="description" content={description} />}
    </Helmet>
  );
};
export default SEO;
