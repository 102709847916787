import React, { Component, ReactNode } from 'react';

import Text from 'components/waypoint/Text';
import Button from 'components/waypoint/Button';
import Icon from 'components/waypoint/Icon';

export const EXPOSE_TO_PUBLIC_ERROR_NAME = 'EXPOSE_TO_PUBLIC';

interface IErrorBoundaryProps {
  children: ReactNode;
}

interface IErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}

class ErrorBoundary extends Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex h-screen">
          <div className="m-auto text-center">
            <Icon icon="exclamation-triangle" className="text-7xl mb-3" />
            <Text look="h1">Something Went Wrong</Text>
            <>
              {this.state.error?.name === EXPOSE_TO_PUBLIC_ERROR_NAME ? (
                <>
                  <Text className="mt-3">{this.state.error.message}</Text>
                  <Text className="mb-3">
                    Please contact{' '}
                    <a href="mailto://support@movableink.com">
                      support@movableink.com
                    </a>
                  </Text>
                </>
              ) : (
                <>
                  <Text className="my-3">
                    We've got all hands on deck and should have a fix soon.
                  </Text>
                  <Text className="mb-3">
                    Please refresh the page or try again later.
                  </Text>
                </>
              )}
            </>
            <Button
              href={{ isExternal: true, href: '/', target: '_self' }}
              className="mt-5 inline-block text-center w-max"
            >
              Go to Home
            </Button>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
