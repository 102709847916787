import { useState } from 'react';

import ReactMde from 'react-mde';

import Application from 'layout/Application';
import SEO from 'layout/SEO';
import Markdown from 'components/util/Markdown';

import 'react-mde/lib/styles/css/react-mde-all.css';
import Styles from './editor.module.scss';

const Editor = () => {
  const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>('write');
  const [content, setContent] = useState<string>('');

  return (
    <Application contentClassName={Styles.editor}>
      <SEO title="Editor" />
      <ReactMde
        value={content}
        onChange={setContent}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={(markdown) =>
          Promise.resolve(
            <Markdown className="w-full h-full" content={markdown} />,
          )
        }
        toolbarCommands={[
          [
            'header',
            'bold',
            'italic',
            'strikethrough',
            'link',
            'quote',
            'code',
            'unordered-list',
            'ordered-list',
            'checked-list',
          ],
        ]}
      />
    </Application>
  );
};
export default Editor;
