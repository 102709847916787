import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import 'antd/dist/antd.css';
import './styles/index.scss';
import './styles/font/font.scss';
import './styles/tailwind/tailwind.css';
import './styles/tailwind/colors.scss';
import './styles/admonition-variables.scss';

import { ResourceProvider, useResource } from 'components/providers/Resource';
import { initIcons } from 'components/waypoint/Icon';
import Loader from 'components/waypoint/Loader';

import NotFound from 'pages/NotFound';
import Guide from 'pages/Guide';
import Update from 'pages/Update';
import Updates from 'pages/Updates';

import AuthProvider from 'root/AuthProvider';
import Editor from 'pages/Editor';

import ErrorBoundary from 'root/ErrorBoundary';

import reportWebVitals from './reportWebVitals';
import Landing from './pages/Landing';

initIcons();

const App = () => {
  const { initialized } = useResource();
  return initialized ? (
    <Switch>
      <Route
        path="/guides/:category/:guideId?"
        exact={true}
        component={Guide}
      />
      <Route path="/updates/:updateId?" exact={true} component={Update} />
      <Route path="/allUpdates" component={Updates} />
      {/* <Route path="/faq" component={FAQ} /> */}
      <Route path="/editor" component={Editor} />
      <Route path="/" exact={true} component={Landing} />
      <Route component={NotFound} />
    </Switch>
  ) : (
    <Loader type="full" />
  );
};

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <ResourceProvider>
          <AuthProvider>
            <App />
          </AuthProvider>
        </ResourceProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
