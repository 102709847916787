import React from 'react';

import Icon from 'components/waypoint/Icon';

import cn from 'styles/classnames';

export type LoaderType = 'spin' | 'full' | 'label';
export interface ILoaderProps {
  id?: string;
  className?: string;
  spinnerClassName?: string;
  /** spin for a spinner, full for a full page loader with backdrop */
  type?: LoaderType;
  fullType?: LoaderType;
  label?: string;
}

const Loader = ({
  id,
  className,
  spinnerClassName,
  type = 'spin',
  fullType = 'label',
  label,
}: ILoaderProps) => {
  return type === 'full' ? (
    <div
      className={cn(
        'flex flex-col w-full h-full bg-white opacity-90 z-5',
        'absolute top-0 left-0 right-0 bottom-0',
        className,
      )}
    >
      <Loader
        type={fullType}
        className="m-auto text-midnight"
        spinnerClassName="text-midnight"
      />
    </div>
  ) : type === 'label' ? (
    <div className={cn('flex flex-row', className)}>
      <Loader className={cn('my-auto', spinnerClassName)} />
      <h1 className="my-auto ml-2 text-lg font-bold">
        {label || 'Loading...'}
      </h1>
    </div>
  ) : (
    <Icon
      id={id}
      className={cn('text-black', className)}
      icon="spinner-third"
      variant="duotone"
      spin={true}
    />
  );
};
export default Loader;
