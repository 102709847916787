import React, { useCallback, useEffect, useState } from 'react';

import {
  FAQType,
  GlossaryType,
  GuideCategoryType,
  GuideSectionType,
  GuidesType,
  SubGuideType,
  UpdateType,
} from 'types/resource';

interface IResourceContext {
  initialized: boolean;
  getCategoryName: (category: GuideCategoryType) => string;
  getGuideCategory: (category: GuideCategoryType) => GuideSectionType[];
  getGuideSection: (
    category: GuideCategoryType,
    sectionId: string,
  ) => GuideSectionType | undefined;
  getGuide: (
    category: GuideCategoryType,
    guideId: string,
  ) => SubGuideType | undefined;
  getGuideContent: (
    category: GuideCategoryType,
    guideId: string,
  ) => Promise<string | undefined>;
  getUpdate: (updateId: string) => UpdateType | undefined;
  getUpdateContent: (updateId: string) => Promise<string | undefined>;
  getUpdateTag: (
    updateId: string,
  ) => { label: string; color: string; textColor: string } | undefined;
  getSuggestedTag: (
    suggestedId: string,
  ) => { label: string; color: string; textColor: string } | undefined;
  getGlossary: (category: GuideCategoryType) => GlossaryType[];
  guides: GuidesType;
  updates: UpdateType[];
  faq: FAQType[];
  glossary: Record<GuideCategoryType, GlossaryType[]>;
}

const defaultContext = {
  initialized: false,
  getCategoryName: () => '',
  getGuideCategory: () => [],
  getGuideSection: () => undefined,
  getGuide: () => undefined,
  getGuideContent: async () => undefined,
  getUpdates: () => [],
  getUpdate: () => undefined,
  getUpdateContent: async () => undefined,
  getUpdateTag: () => undefined,
  getSuggestedTag: () => undefined,
  getGlossary: () => [],
  guides: {
    user: [],
    technical: [],
  },
  updates: [],
  faq: [],
  glossary: {
    user: [],
    technical: [],
  },
};
export const ResourceContext =
  React.createContext<IResourceContext>(defaultContext);

export const ResourceProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [guides, setGuides] = useState<GuidesType>();
  const [faq, setFAQ] = useState<FAQType[]>();
  const [updates, setUpdates] = useState<UpdateType[]>();
  const [glossary, setGlossary] =
    useState<Record<GuideCategoryType, GlossaryType[]>>();

  useEffect(() => {
    fetch('/resources/guides.json')
      .then((res) => res.text())
      .then((text) => {
        const resources = JSON.parse(text);
        setGuides(resources['guides']);
        setInitialized(true);
      });
    fetch('/resources/faq.json')
      .then((res) => res.text())
      .then((text) => {
        const resources = JSON.parse(text);
        setFAQ(resources['faq']);
      });
    fetch('/resources/glossary.json')
      .then((res) => res.text())
      .then((text) => {
        const resources = JSON.parse(text);
        setGlossary(resources['glossary']);
      });
    fetch('/resources/updates.json')
      .then((res) => res.text())
      .then((text) => {
        const resources = JSON.parse(text);
        setUpdates(resources['updates']);
      });
  }, []);

  const getCategoryName = useCallback((category: GuideCategoryType) => {
    switch (category) {
      case 'technical':
        return 'Developer Resources';
      case 'user':
        return 'Using Da Vinci';
      default:
        return '';
    }
  }, []);

  const getGuideCategory = useCallback(
    (category: GuideCategoryType) => {
      return guides ? guides[category] : [];
    },
    [guides],
  );

  const getGuideCategoryGuides = useCallback(
    (category: GuideCategoryType) => {
      const sections = getGuideCategory(category);
      const [guideSubSection] = sections;
      if (guideSubSection.guideSubSection) {
        const subSectionGuidesFlatMapped = sections.flatMap(
          ({ guideSubSection }) => guideSubSection,
        );
        const subGuidesFlatMapped = subSectionGuidesFlatMapped.flatMap(
          ({ guides }) => guides,
        );
        return subGuidesFlatMapped;
      } else {
        const guidesFlatMapped = sections.flatMap(({ guides }) => guides);
        return guidesFlatMapped;
      }
    },
    [getGuideCategory],
  );

  const getGuideSection = useCallback(
    (category: GuideCategoryType, sectionId: string) => {
      const categoryGuides = getGuideCategory(category);
      return categoryGuides
        ? categoryGuides.find(({ id }) => id === sectionId)
        : undefined;
    },
    [getGuideCategory],
  );

  const getGuide = useCallback(
    (category: GuideCategoryType, guideId: string) => {
      const guides = getGuideCategoryGuides(category);
      return guides.find(({ id }) => id === guideId);
    },
    [getGuideCategoryGuides],
  );
  const getGuideContent = useCallback(
    async (category: GuideCategoryType, guideId: string) => {
      const guide = getGuide(category, guideId);
      if (guide) {
        const response = await fetch(guide.file);
        const text = await response.text();
        return text;
      } else {
        return '';
      }
    },
    [getGuide],
  );

  const getUpdate = useCallback(
    (updateId) => {
      return (updates || []).find(({ id }) => id === updateId);
    },
    [updates],
  );
  const getUpdateContent = useCallback(
    async (updateId) => {
      const update = getUpdate(updateId);
      if (update) {
        const response = await fetch(update.file);
        const text = await response.text();
        return text;
      } else {
        return '';
      }
    },
    [getUpdate],
  );
  const getUpdateTag = useCallback(
    (updateId: string) => {
      const update = getUpdate(updateId);
      if (update) {
        switch (update.tag) {
          case 'analytics':
            return {
              label: 'Analytics',
              color: '#FFEDD5',
              textColor: '#C2410C',
            };
          case 'creatives':
            return {
              label: 'Creatives',
              color: '#E0F2FE',
              textColor: '#0369A1',
            };
          case 'campaigns':
            return {
              label: 'Campaigns',
              color: '#EDE9FE',
              textColor: '#6D28D9',
            };
          case 'templates':
            return {
              label: 'Templates',
              color: '#F1F5F9',
              textColor: '#334155',
            };
          case 'miscellaneous':
            return {
              label: 'Miscellaneous',
              color: '#FCE7F3',
              textColor: '#BE185D',
            };
          case 'updates':
            return {
              label: 'Updates',
              color: '#FCE7F3',
              textColor: '#BE185D',
            };
          case 'promotions':
            return {
              label: 'Promotions',
              color: '#DCFCE7',
              textColor: '#15803D',
            };
        }
      }
    },
    [getUpdate],
  );

  const getSuggestedTag = useCallback((suggestedId: string) => {
    if (suggestedId) {
      switch (suggestedId) {
        case 'analytics':
          return {
            label: 'Analytics',
            color: '#FFEDD5',
            textColor: '#C2410C',
          };
        case 'creatives':
          return {
            label: 'Creatives',
            color: '#E0F2FE',
            textColor: '#0369A1',
          };
        case 'campaigns':
          return {
            label: 'Campaigns',
            color: '#EDE9FE',
            textColor: '#6D28D9',
          };
        case 'templates':
          return {
            label: 'Templates',
            color: '#F1F5F9',
            textColor: '#334155',
          };
        case 'miscellaneous':
          return {
            label: 'Miscellaneous',
            color: '#FCE7F3',
            textColor: '#BE185D',
          };
        case 'updates':
          return {
            label: 'Updates',
            color: '#FCE7F3',
            textColor: '#BE185D',
          };
        case 'promotions':
          return {
            label: 'Promotions',
            color: '#DCFCE7',
            textColor: '#15803D',
          };
      }
    }
  }, []);

  const getGlossary = useCallback(
    (category: GuideCategoryType) => (glossary ? glossary[category] || [] : []),
    [glossary],
  );

  return (
    <ResourceContext.Provider
      value={{
        initialized,
        getCategoryName,
        getGuideCategory,
        getGuideSection,
        getGuide,
        getGuideContent,
        getUpdate,
        getUpdateContent,
        getUpdateTag,
        getSuggestedTag,
        getGlossary,
        guides: guides || {
          user: [],
          technical: [],
        },
        updates: updates || [],
        faq: faq || [],
        glossary: glossary || {
          user: [],
          technical: [],
        },
      }}
    >
      {children}
    </ResourceContext.Provider>
  );
};
export const useResource = () => {
  const context = React.useContext(ResourceContext);
  if (!context) {
    throw new Error('useResource not used as descendent of ResourceProvider');
  }

  return context;
};
