import Icon from 'components/waypoint/Icon';
import Text from 'components/waypoint/Text';

import Logo from 'components/waypoint/Logo';

interface IFooterProps {
  className?: string;
}
const Footer = ({ className }: IFooterProps) => {
  return (
    <footer className="mt-auto bg-tealPath-900">
      <div className=" py-1 pb-5 pt-8 pr-16   pl-9  ">
        <div className="flex flex-row justify-between w-full text-xs text-center border-b border-gray-200 relative top-0 z-10 text-gray-400">
          <Logo type="wide-light" className="self-center h-7" />
          <div className="flex flex-col pb-6">
            <Text
              as="h5"
              look="h3"
              className="font-extrabold text-left text-white tracking-custom"
            >
              SUPPORT
            </Text>
            <Text className="mt-2 text-white text-sm">
              Need help from an expert? We’re right here for you.
            </Text>
            <div className="self-start flex flex-row items-center text-content-primary">
              <a
                href="mailto:support@movableink.com"
                className="px-2 py-1 mt-2 text-sm font-bold bg-white rounded hover:text-content-accent"
              >
                <Icon
                  icon="envelope"
                  variant="thin"
                  className="text-md mr-1.5"
                />
                Get Support
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between w-full relative py-6">
          <div>
            <p className="text-white text-xs">
              &copy; Copyright 2023 Movable Ink
            </p>
          </div>

          <div>
            <a href="mailto:info@coherentpath.com" className="mr-3.5">
              <Icon
                icon="envelope-square"
                variant="solid"
                className="text-white text-xl"
              />
            </a>
            <a
              href="https://www.facebook.com/coherentpathinc/"
              target="_blank"
              rel="noreferrer"
              className="mr-3.5"
            >
              <Icon
                icon="facebook-square"
                variant="brand"
                className="text-white text-xl"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/coherentpath/"
              target="_blank"
              rel="noreferrer"
              className="mr-3.5"
            >
              <Icon
                icon="linkedin"
                variant="brand"
                className="text-white text-xl"
              />
            </a>
            <a
              href="https://twitter.com/coherentpath"
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                icon="twitter-square"
                variant="brand"
                className="text-white text-xl"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
