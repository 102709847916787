import Text from 'components/waypoint/Text';
import UpdatesList from 'components/features/update/UpdatesList';
import Application from 'layout/Application';
import Content from 'layout/Content';

import DnaBackground from 'public/dna.svg';

const Updates = () => {
  return (
    <Application>
      <Content className="h-auto" contentClassName="pb-20 max-w-none">
        <div className="bg-slate-100 relative h-50">
          <div className="flex flex-col justify-center h-full pl-10">
            <Text look="h1" className="mb-4">
              Latest Updates
            </Text>
            <Text className="text-md">
              We constantly release new features and updates — find out all you
              need to know here.
            </Text>
            <div className="absolute right-0 top-10">
              <img src={DnaBackground} alt="" />
            </div>
          </div>
        </div>
        <div className="flex justify-center pt-14">
          <UpdatesList />
        </div>
      </Content>
    </Application>
  );
};
export default Updates;
