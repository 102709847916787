import { PropsWithChildren } from 'react';

import cn from 'styles/classnames';

interface IContentProps {
  className?: string;
  contentClassName?: string;
}
const Content = ({
  className,
  contentClassName,
  children,
}: PropsWithChildren<IContentProps>) => {
  return (
    <div
      className={cn('flex flex-row justify-center w-full h-full', className)}
    >
      <div className={cn('max-w-screen-xl w-full', contentClassName)}>
        {children}
      </div>
    </div>
  );
};
export default Content;
