import clsx from 'clsx';
import { overrideTailwindClasses } from 'tailwind-override';

import overrideConfig from './tailwind/tailwind.json';
/**
 * Works as classnames as usual but also overrides tailwind classnames where the right-most arg takes precedence
 *
 * @param args - strings, objects, arrays, anything that classnames typically supports
 * @returns - flat class list as a string
 */
const cn = (...args: any) =>
  overrideTailwindClasses(clsx(...args), {
    // @ts-ignore
    tailwindProperties: overrideConfig,
  });
export default cn;
