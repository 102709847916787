import { useEffect, useState } from 'react';

import { ICommonProps } from 'components/waypoint/util';
import Text from 'components/waypoint/Text';
import Content from 'layout/Content';
import { GuideCategoryType } from 'types/resource';
import Markdown from 'components/util/Markdown';

interface IMarkdownGuideProps extends ICommonProps {
  category: GuideCategoryType;
  title: string;
  guideFile: string;
}
const MarkdownGuide = ({
  className,
  title,
  guideFile,
}: IMarkdownGuideProps) => {
  const [postMarkdown, setPostMarkdown] = useState('');
  useEffect(() => {
    fetch(guideFile)
      .then((response) => response.text())
      .then((text) => {
        setPostMarkdown(text);
      });
  }, [guideFile]);
  return (
    <div className="pt-14">
      <Text look="h2" className="mb-8 leading-7.5 text-3xl">
        {title}
      </Text>
      <Content className="h-fit" contentClassName="pb-20 flex flex-row">
        <Markdown content={postMarkdown || ''} />
        <div className="p-4 mt-14 sticky top-8 w-54 xl:w-70"></div>
      </Content>
    </div>
  );
};
export default MarkdownGuide;
