import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkAdmonitions from 'remark-admonitions';
import remarkParse from 'remark-parse';
import rehypeRaw from 'rehype-raw';

import { NavHashLink } from 'react-router-hash-link';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

import 'styles/article/admonitions.css';
import 'styles/article/github.css';

import cn from 'styles/classnames';

import Styles from './Markdown.module.scss';

interface IMarkdownProps {
  className?: string;
  content: string;
}
const RouterLink = (props: any) => {
  return props.href.match(/^(https?:)?\/\//) ? (
    <a href={props.href}>{props.children}</a>
  ) : (
    <NavHashLink to={props.href}>{props.children}</NavHashLink>
  );
};
const Markdown = ({ className, content }: IMarkdownProps) => {
  return (
    <div className={cn('markdown-body max-w-xl', Styles, className)}>
      <ReactMarkdown
        children={content || ''}
        remarkPlugins={[remarkParse, remarkAdmonitions, remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        components={{
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            return !inline && match ? (
              <SyntaxHighlighter
                children={String(children).replace(/\n$/, '')}
                style={vscDarkPlus}
                customStyle={{ padding: 16, margin: 0 }}
                language={match[1]}
                PreTag="div"
                {...props}
              />
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
          a: RouterLink,
        }}
      />
    </div>
  );
};
export default Markdown;
