import { useMemo } from 'react';

import { NavLink } from 'react-router-dom';

import { ICommonProps } from 'components/waypoint/util';
import Tree from 'components/waypoint/Tree';
import { GuideCategoryType } from 'types/resource';
import { useResource } from 'components/providers/Resource';

import cn from 'styles/classnames';

interface ISectionListProps extends ICommonProps {
  category: GuideCategoryType;
}
const SectionList = ({ id, className, category }: ISectionListProps) => {
  const { getGuideCategory } = useResource();
  const sections = useMemo(() => {
    const guides = getGuideCategory(category);
    console.log('guides', guides);
    const guideList = [
      {
        id: 'overview',
        label: 'Overview',
        items: [
          {
            id: 'coherentpathtutorial',
            label: (
              <NavLink
                activeClassName={cn('text-content-accent font-bold', id)}
                className={cn(
                  'sectionList-links text-sm text-content-primary hover:font-bold hover:text-content-accent',
                )}
                to={`/guides/${category}/coherentpathtutorial`}
              >
                Welcome
              </NavLink>
            ),
          },
          {
            id: 'coherentpathbasics',
            label: (
              <NavLink
                activeClassName={cn('text-content-accent font-bold', id)}
                className={cn(
                  'sectionList-links text-sm text-content-primary hover:font-bold hover:text-content-accent',
                )}
                to={`/guides/${category}/coherentpathbasics`}
              >
                Da Vinci Basics
              </NavLink>
            ),
          },
          {
            id: 'accessingyouraccount',
            label:
              category === 'user' ? (
                <NavLink
                  activeClassName={cn('text-content-accent font-bold', id)}
                  className={cn(
                    'sectionList-links text-sm text-content-primary hover:font-bold hover:text-content-accent',
                  )}
                  to={`/guides/user/accessingyouraccount`}
                >
                  Accessing Your Account
                </NavLink>
              ) : null,
          },
          {
            id: 'acountissues',
            label:
              category === 'user' ? (
                <NavLink
                  activeClassName={cn('text-content-accent font-bold', id)}
                  className={cn(
                    'sectionList-links text-sm text-content-primary hover:font-bold hover:text-content-accent',
                  )}
                  to={`/guides/user/account_issues`}
                >
                  User account assistance guide
                </NavLink>
              ) : null,
          },
          {
            id: 'glossary',
            label: (
              <NavLink
                activeClassName={cn('text-content-accent font-bold', id)}
                className={cn(
                  'sectionList-links text-sm text-content-primary hover:text-content-accent',
                )}
                to={`/guides/${category}/glossary`}
              >
                Glossary
              </NavLink>
            ),
          },
          {
            id: 'allguides',
            label: (
              <NavLink
                activeClassName={cn('text-content-accent font-bold', id)}
                className={cn(
                  'sectionList-links text-sm text-content-primary hover:text-content-accent',
                )}
                to={`/guides/${category}/allguides`}
              >
                All guides
              </NavLink>
            ),
          },
        ],
      },
      ...guides.map(({ id, title, guides, guideSubSection }) => ({
        id,
        label: title,
        items: guideSubSection
          ? guideSubSection.map(({ title, guides, hide }) => ({
              title: !(hide === true) ? title : '',
              label: guides.map(
                ({ id, title, hideGuide }) =>
                  !(hideGuide === true) && (
                    <li className="pl-8 mt-2" key={id}>
                      <NavLink
                        activeClassName={cn('text-content-accent font-bold', {
                          'text-content-secondary': title.includes('Coming'),
                        })}
                        className={cn(
                          'sectionList-links text-sm text-content-primary hover:text-content-accent',
                          {
                            'text-content-secondary': title.includes('Coming'),
                          },
                        )}
                        to={`/guides/${category}/${id}`}
                      >
                        {title}
                      </NavLink>
                    </li>
                  ),
              ),
            }))
          : guides.map(({ id, title, subGuides }) => ({
              label: (
                <NavLink
                  activeClassName={cn('text-content-accent font-bold', {
                    'text-content-secondary': title.includes('Coming'),
                  })}
                  className={cn(
                    'sectionList-links text-sm text-content-primary hover:text-content-accent',
                    {
                      'text-content-secondary': title.includes('Coming'),
                    },
                  )}
                  to={`/guides/${category}/${id}`}
                >
                  {title}
                </NavLink>
              ),
              subGuides:
                subGuides &&
                subGuides.map(({ id, title }) => {
                  return (
                    <li className="text-sm last: mb-0">
                      <NavLink
                        activeClassName={cn('text-content-accent font-bold', {
                          'text-content-secondary': title.includes('Coming'),
                        })}
                        className={cn(
                          'sectionList-links text-sm text-content-primary hover:font-bold hover:text-content-accent',
                          {
                            'text-content-secondary': title.includes('Coming'),
                          },
                        )}
                        to={`/guides/${category}/${id}`}
                      >
                        {title}
                      </NavLink>
                    </li>
                  );
                }),
            })),
      })),
    ];
    return guideList;
  }, [id, category, getGuideCategory]);

  return <Tree id={id} className={className} items={sections} />;
};
export default SectionList;
