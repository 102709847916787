import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
} from 'react';

import {
  useAuth0 as useAuth0Client,
  Auth0Provider as Auth0ClientProvider,
  AppState,
} from '@auth0/auth0-react';

import { useHistory } from 'react-router-dom';

import Loader from 'components/waypoint/Loader';
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from 'common/envs';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IAuthContext {}

const AuthContext = createContext<IAuthContext | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within our AuthProvider');
  }
  return context;
};

const Auth0Provider = ({ children }: PropsWithChildren<{}>) => {
  const {
    isLoading: loading,
    isAuthenticated,
    error,
    loginWithRedirect,
  } = useAuth0Client();

  useEffect(() => {
    // Move error to ErrorBoundary (report to rollbar),
    // since the error won't make sense to the user
    if (error && !loading) {
      throw error;
    }
  }, [error, loading]);

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      // redirect to auth0 login as our landing page
      loginWithRedirect({
        appState: {
          returnTo: `${window.location.pathname}${window.location.search}`,
        },
      });
    }
  }, [error, isAuthenticated, loading, loginWithRedirect]);

  return loading ? (
    <Loader type="full" />
  ) : isAuthenticated ? (
    <AuthContext.Provider value={{}}>{children}</AuthContext.Provider>
  ) : null;
};

const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  const history = useHistory();
  const onRedirectCallback = useCallback(
    (appState?: AppState) => {
      history.replace(appState?.returnTo || window.location.pathname);
    },
    [history],
  );
  return (
    <Auth0ClientProvider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      audience={AUTH0_AUDIENCE}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <Auth0Provider>{children}</Auth0Provider>
    </Auth0ClientProvider>
  );
};

export default AuthProvider;
