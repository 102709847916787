import React from 'react';

import cn from 'styles/classnames';

import LogoDefault from './sources/logo.svg';
import LogoWide from './sources/logo-wide.svg';
import LogoWideLight from './sources/mi-logo-wide-white.svg';
import LogoSmall from './sources/logo-small.svg';
import LogoSmallLight from './sources/logo-small-light.svg';

export interface ILogoProps {
  id?: string;
  className?: string;
  type?: 'default' | 'wide' | 'wide-light' | 'small' | 'small-light';
}
const Logo = React.forwardRef(
  (
    { id, className, type = 'default' }: ILogoProps,
    ref: React.Ref<HTMLImageElement>,
  ) => {
    return (
      <img
        id={id}
        className={cn(className)}
        src={
          type === 'wide'
            ? LogoWide
            : type === 'small'
            ? LogoSmall
            : type === 'wide-light'
            ? LogoWideLight
            : type === 'small-light'
            ? LogoSmallLight
            : LogoDefault
        }
        alt="Movable Ink"
        ref={ref}
      />
    );
  },
);
export default Logo;
