import { useMemo, useState } from 'react';

import { NavHashLink } from 'react-router-hash-link';
import { kebabCase } from 'lodash';

import Text from 'components/waypoint/Text';
import { useResource } from 'components/providers/Resource';
import cn from 'styles/classnames';
import { GuideCategoryType } from 'types/resource';

interface IGlossaryProps {
  category: GuideCategoryType;
}
const Glossary = ({ category }: IGlossaryProps) => {
  const { getGlossary } = useResource();
  //list of enabled letters and campare against full list of letters
  const glossary = useMemo(
    () => getGlossary(category),
    [getGlossary, category],
  );

  const [activeChar, setActiveChar] = useState('');

  const alphabetList = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];

  const activeChars = glossary.map(({ term }) => term.toLowerCase().charAt(0));

  const checkId = (char: string) => {
    if (char) {
      return char;
    }
    return '';
  };

  return (
    <div className="px-10 py-14">
      <div className="pb-8">
        <Text look="h2" className="mb-2 leading-7.5 text-3xl">
          Glossary
        </Text>
        <Text>
          This glossary defines common terms that you might see as you're
          working in Coherent Path, or throughout our Help Center.
        </Text>
      </div>
      <div className="pb-8 flex flex-row">
        {alphabetList.map((char, idx) => (
          <div className="border-r border-border-tertiary mr-2 pr-2">
            <button
              className={cn(
                'text-md text-content-primary disabled:text-gray-200',
                activeChar === char
                  ? 'text-content-accent font-bold border-b border-border-accent'
                  : '',
              )}
              disabled={!activeChars.includes(char.toLowerCase())}
            >
              <NavHashLink
                key={idx}
                className={cn('', {
                  'text-gray-400 cursor-default': !activeChars.includes(
                    char.toLowerCase(),
                  ),
                  'hover:text-content-accent': activeChars.includes(
                    char.toLowerCase(),
                  ),
                  'hover:text-gray-400': !activeChars.includes(
                    char.toLowerCase(),
                  ),
                })}
                to={`#${char.toLowerCase()}`}
                onClick={() => setActiveChar(char)}
              >
                {char}
              </NavHashLink>
            </button>
          </div>
        ))}
      </div>
      <dl className="mt-5 text-content-primary max-w-xl">
        {glossary.map(({ term, definition }, idx) => (
          <div
            key={idx}
            id={checkId(term.charAt(0).toLowerCase())}
            className={cn('mb-10', { 'mt-4': idx > 0 })}
          >
            <h1 id={kebabCase(term)} className="text-xl mb-2 font-semibold">
              {term}
            </h1>
            <dd className="text-md">{definition}</dd>
          </div>
        ))}
      </dl>
    </div>
  );
};
export default Glossary;
