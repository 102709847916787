import { useEffect, useMemo, useState } from 'react';

import Text from 'components/waypoint/Text';
import Content from 'layout/Content';
import SEO from 'layout/SEO';
import { useResource } from 'components/providers/Resource';
import Loader from 'components/waypoint/Loader';

import { ICommonProps } from 'components/waypoint/util';
import { GuideCategoryType } from 'types/resource';
import Markdown from 'components/util/Markdown';

import GuideSideNav from './GuideSideNav';

import Styles from './GuideContent.module.scss';

interface IGuideContentProps extends ICommonProps {
  category: GuideCategoryType;
  guideId: string;
}
const GuideContent = ({ category, guideId }: IGuideContentProps) => {
  const { getGuide, getGuideContent } = useResource();
  const [initialized, setInitialized] = useState<string | undefined>(undefined);
  const [content, setContent] = useState<string>();
  const [guideNavText, setGuideNavText] = useState<any>();

  const guide = useMemo(
    () => getGuide(category, guideId),
    [category, getGuide, guideId],
  );

  useEffect(() => {
    if (!initialized || guideId !== initialized) {
      getGuideContent(category, guideId).then((content) => {
        setInitialized(guideId);
        setContent(content);
        setGuideNavText(getSideNavText());
      });
    }
  }, [initialized, content, getGuideContent, category, getGuide, guideId]);

  const getSideNavText = () => {
    const sideNavText = document.querySelectorAll('h3, h4');

    return sideNavText;
  };

  // simple function to use for callback in the intersection observer
  const changeNav = (entries: any) => {
    entries.forEach((entry: any) => {
      // verify the element is intersecting
      if (entry.isIntersecting) {
        document
          .querySelector('.GuideContent_active__272rc')
          ?.classList.remove('GuideContent_active__272rc');

        const id = entry.target.getAttribute('id');
        const sections = document.querySelectorAll('h3, h4');
        sections.forEach((section) => {
          if (section.id === id) {
            const targetEl = document.getElementById('nav-' + id);
            targetEl?.classList.add(Styles.active);
          }
        });
      }
    });
  };

  // init the observer
  const options = {
    threshold: 0,
    rootMargin: '-50% 0% -50% 0%',
  };

  const observer = new IntersectionObserver(changeNav, options);

  // target the elements to be observed
  const sections = document.querySelectorAll('h3, h4');
  sections.forEach((section) => {
    observer.observe(section);
  });
  return guide ? (
    <article className="pt-14 pb-20 px-10">
      <SEO title={guide.title} />
      {initialized ? (
        <div className="flex flex-row">
          <div className="flex flex-col">
            <Text look="h2" className="mb-8 leading-7.5 text-3xl">
              {guide.title.replace('(Coming Soon)', '')}
            </Text>
            <Markdown content={content || ''} />
          </div>
          {guideNavText && guideNavText.length > 0 ? (
            <GuideSideNav navigationItems={guideNavText} />
          ) : (
            <div className="p-4 mt-14 sticky top-8 w-54 xl:w-70"></div>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </article>
  ) : (
    <Content>
      <Text look="h1">Guide not found, please check the URL</Text>
    </Content>
  );
};
export default GuideContent;
