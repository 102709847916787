import { format, parse } from 'date-fns';

import { useResource } from 'components/providers/Resource';
import Text from 'components/waypoint/Text';
import Button from 'components/waypoint/Button';
import Timeline from 'components/waypoint/Timeline';

interface IUpdatesListProps {
  className?: string;
}
const UpdatesList = ({ className }: IUpdatesListProps) => {
  const { updates, getUpdateTag } = useResource();

  return (
    <Timeline
      className={className}
      items={updates.map(({ id, date, title, description }, idx) => {
        const tagConfig = getUpdateTag(id);
        return (
          <div className="flex flex-col p-8 my-5 mr-0 rounded-lg shadow-md md:mr-40 max-w-1xl">
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row items-center col-span-1">
                {tagConfig && (
                  <p
                    style={{
                      background: tagConfig.color,
                      color: tagConfig.textColor,
                    }}
                    className="w-auto px-2 py-1 text-sm rounded text-content-primary"
                  >
                    {tagConfig.label}
                  </p>
                )}
              </div>
              <Text className="flex flex-row items-center col-span-1 text-sm">
                {format(parse(date, 'yyyy-MM-dd', new Date()), 'MMMM yyyy')}
              </Text>
            </div>
            <Text className="mt-1 font-bold" look="h6">
              {title}
            </Text>
            <ul className="mt-4 mb-1 list-disc pl-6 max-w-xl">
              {description.map((desc, idx) => (
                <li key={idx} className="mb-1 text-content-secondary last:mb-0">
                  <Text className="text-content-primary text-sm">{desc}</Text>
                </li>
              ))}
            </ul>
            <Button
              variant="text"
              className="self-start px-0 py-0 text-sm font-bold hover:text-content-accent hover:underline"
              href={{ href: `/updates/${id}` }}
            >
              See release note
            </Button>
          </div>
        );
      })}
    />
  );
};
export default UpdatesList;
