import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import cn from 'styles/classnames';

export const initIcons = () => {
  library.add(fal);
  library.add(far);
  library.add(fas);
  library.add(fad);
  library.add(fab);
};

export type IconVariant = 'thin' | 'solid' | 'line' | 'duotone' | 'brand';

export interface IIconProps {
  id?: string;
  className?: string;
  /** classname of the background layer */
  backgroundClassName?: string;
  /** the fontawesome key of the icon */
  icon: IconName;
  variant?: IconVariant;
  spin?: boolean;
  background?: IconName;
  onClick?: (e: React.MouseEvent<HTMLOrSVGElement>) => void;
}

const Layers = ({
  className,
  wrap,
  children,
}: React.PropsWithChildren<{ className?: string; wrap: boolean }>) => {
  return wrap ? (
    <div className={cn('fa-layers fa-fw flex flex-col', className)}>
      {children}
    </div>
  ) : (
    <>{children}</>
  );
};

const Icon = React.forwardRef(
  (
    {
      id,
      className,
      backgroundClassName,
      icon,
      variant = 'thin',
      spin = false,
      background,
      onClick,
    }: IIconProps,
    ref,
  ) => (
    <Layers className={className} wrap={Boolean(background)}>
      {Boolean(background) && (
        <FontAwesomeIcon
          id={id ? `${id}-background` : undefined}
          className={cn('text-gray-200', backgroundClassName)}
          icon={['fas', background!]}
          transform="grow-14"
        />
      )}
      <FontAwesomeIcon
        id={id}
        icon={[
          variant === 'thin'
            ? 'fal'
            : variant === 'solid'
            ? 'fas'
            : variant === 'duotone'
            ? 'fad'
            : variant === 'brand'
            ? 'fab'
            : 'far',
          icon,
        ]}
        spin={spin}
        forwardedRef={ref}
        className={Boolean(background) ? '' : className}
        onClick={onClick}
        role={onClick ? 'button' : undefined}
      />
    </Layers>
  ),
);
export default Icon;
