import { Link } from 'react-router-dom';

import Text from 'components/waypoint/Text';
import Content from 'layout/Content';
import SEO from 'layout/SEO';
import Application from 'layout/Application';
import UpdatesList from 'components/features/update/UpdatesList';
import Search from 'components/util/Search';

import LandingBackground from 'public/landing_background.svg';
import DeveloperResources from 'public/developer_resources.svg';
import UsingCoherentPath from 'public/using_coherentPath.svg';

const Landing = () => {
  return (
    <Application hideSearch={true}>
      <SEO
        title="Help Center"
        description="We’re here to help you learn about Coherent Path. Browse our collection of helpful articles and find answers to your questions quickly"
      />
      <Content
        className="h-auto"
        contentClassName="flex flex-col items-center justify-center relative max-w-none"
      >
        <div className="w-full">
          <img src={LandingBackground} alt="" className="w-full" />
        </div>
        <div className="absolute z-10 flex flex-col items-center">
          <Text look="h1" className="text-content-primary text-5xl">
            Hi. How can we help?
          </Text>
          <Search
            className="mt-5 w-full"
            dropdownClassName="w-119"
            size="lg"
            placeholder="Search by Keywords or Titles"
          />
        </div>
      </Content>
      <Content className="h-auto" contentClassName="px-10 pt-14 pb-20">
        <Text look="h2">Help by Topic</Text>
        <div className="grid grid-cols-12 gap-8 mt-8">
          <div className="col-span-6 border shadow-md rounded-lg hover:border-border-accent">
            <Link
              to={`/guides/user/coherentpathtutorial`}
              className="flex flex-col rounded-lg"
            >
              <img
                src={UsingCoherentPath}
                alt=""
                className="rounded-tl-lg rounded-tr-lg"
              />
              <div className="p-4">
                <Text
                  look="label"
                  as="p"
                  className="text-content-accent mb-1 font-normal text-sm tracking-custom"
                >
                  GUIDES, TUTORIALS &amp; MORE
                </Text>
                <Text look="h3" className="text-content-primary">
                  Using Da Vinci
                </Text>
                <Text className="mt-2">
                  Everything you need to get started and work in Da Vinci.
                </Text>
              </div>
            </Link>
          </div>
          <div className="col-span-6 border rounded-lg shadow-md hover:border-border-accent">
            <Link to={`/guides/technical/allguides`} className="flex flex-col ">
              <img
                src={DeveloperResources}
                alt=""
                className="rounded-tl-lg rounded-tr-lg"
              />
              <div className="p-4">
                <Text
                  look="label"
                  as="p"
                  className="text-content-accent mb-1 font-normal text-sm tracking-custom"
                >
                  ALL THINGS TECH
                </Text>
                <Text look="h3" className="text-content-primary">
                  Developer Resources
                </Text>
                <Text className="mt-2">
                  Everything you need to get started and work in Da Vinci.
                </Text>
              </div>
            </Link>
          </div>
        </div>
        <Text look="h2" className="mt-24 mb-2">
          Latest Updates
        </Text>
        <Text className="mb-4">
          Up-to-the minute updates on{' '}
          <span className="font-bold text-content-accent">
            <Link
              to="/allUpdates"
              className="text-content-accent text-md font-bold hover:text-content-accent hover:underline"
            >
              what's new
            </Link>
          </span>{' '}
          at Da Vinci
        </Text>
        <UpdatesList />
        <div className="pt-8">
          <Link
            to="/allUpdates"
            className="text-content-accent text-md font-bold hover:text-content-accent hover:underline"
          >
            See all updates
          </Link>
        </div>
      </Content>
    </Application>
  );
};
export default Landing;
