import React from 'react';

import { Link } from 'react-router-dom';

import Icon, { IIconProps } from 'components/waypoint/Icon';

import cn from 'styles/classnames';

import { ICommonProps } from '../util';
import {
  ButtonVariantTypes,
  colorMap,
  ColorTypes,
  sizeMap,
  SizeTypes,
  variantMap,
} from './styles';
import Text from '../Text';
import Tooltip, { ITooltipProps } from '../Tooltip';

export interface IButtonProps extends ICommonProps {
  labelClassName?: string;
  variant?: ButtonVariantTypes;
  color?: ColorTypes;
  size?: SizeTypes;
  disabled?: boolean;
  loading?: boolean;
  label?: string;
  leftIcon?: IIconProps;
  rightIcon?: IIconProps;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'reset' | 'submit';
  href?: {
    href: string;
    target?: '_blank' | '_self';
    isExternal?: boolean;
  };
  isPlain?: boolean;
  tooltip?: ITooltipProps;
}

const TooltipContainer = ({
  tooltip,
  children,
}: React.PropsWithChildren<{ tooltip?: ITooltipProps }>) => {
  return tooltip ? <Tooltip {...tooltip}>{children}</Tooltip> : <>{children}</>;
};

const Container = ({
  id,
  className,
  href,
  onClick,
  loading,
  disabled,
  type,
  tooltip,
  children,
}: React.PropsWithChildren<IButtonProps>) => {
  return (
    <TooltipContainer tooltip={tooltip}>
      {href?.isExternal ? (
        <a
          id={id}
          className={className}
          href={href.href}
          target={href.target}
          aria-busy={loading}
        >
          {children}
        </a>
      ) : href ? (
        <Link
          id={id}
          className={className}
          to={href.href}
          target={href.target}
          aria-busy={loading}
        >
          {children}
        </Link>
      ) : (
        <button
          id={id}
          className={className}
          onClick={onClick}
          aria-busy={loading}
          disabled={disabled}
          type={type}
        >
          {children}
        </button>
      )}
    </TooltipContainer>
  );
};
const Button = ({
  id,
  className,
  labelClassName,
  variant = 'default',
  color = 'primary',
  size = 'md',
  loading = false,
  disabled = false,
  label,
  leftIcon,
  rightIcon,
  onClick,
  type = 'button',
  href,
  isPlain = false,
  tooltip,
  children,
}: React.PropsWithChildren<IButtonProps>) => {
  return (
    <Container
      id={id}
      className={cn(
        'flex flex-row items-center justify-center',
        {
          'cursor-not-allowed': disabled,
          'cursor-wait': loading,
        },
        {
          [sizeMap[size]]: !isPlain,
        },
        {
          [variantMap[variant]]: !isPlain,
        },
        {
          [colorMap[variant][color]]: !loading && !isPlain,
          'bg-background-tertiary text-content-primary':
            loading && variant !== 'text' && !isPlain,
          'text-content-primary': loading && variant === 'text' && !isPlain,
        },
        className,
      )}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
      type={type}
      href={href}
      tooltip={tooltip}
    >
      {loading ? (
        <Icon
          className={cn({ 'mr-1': label || children })}
          icon="spinner-third"
          spin={true}
        />
      ) : leftIcon ? (
        <Icon
          {...leftIcon}
          className={cn({ 'mr-1': label || children }, leftIcon.className)}
        />
      ) : null}
      {label ? (
        <Text
          className={cn(
            'text-current',
            {
              'pb-2px pt-3px border-b border-transparent':
                variant === 'text' && !isPlain,
            },
            {
              'hover:border-border-accent':
                variant === 'text' && color === 'primary' && !isPlain,
              'hover:border-border-warning':
                variant === 'text' && color === 'warning' && !isPlain,
              'hover:border-border-error':
                variant === 'text' && color === 'error' && !isPlain,
            },
            labelClassName,
          )}
          look="span"
        >
          {label}
        </Text>
      ) : (
        children
      )}
      {rightIcon && (
        <Icon
          {...rightIcon}
          className={cn({ 'ml-1': label || children }, rightIcon.className)}
        />
      )}
    </Container>
  );
};
export default Button;
