import { PropsWithChildren } from 'react';

import cn from 'styles/classnames';

import Footer from 'layout/Footer';

import Header from './Header';

// const NAV_LINKS = [
//   {
//     label: 'FAQ',
//     path: '/faq',
//   },
// ];

const Application = ({
  contentClassName,
  hideSearch = false,
  children,
}: PropsWithChildren<{ contentClassName?: string; hideSearch?: boolean }>) => {
  return (
    <div className="flex flex-col w-full h-full overflow-x-hidden">
      <Header hideSearch={hideSearch} />
      <main className={cn('flex-grow', contentClassName)}>{children}</main>
      <Footer />
    </div>
  );
};
export default Application;
