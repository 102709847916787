import { Link } from 'react-router-dom';

import Text from 'components/waypoint/Text';
import Content from 'layout/Content';
import SEO from 'layout/SEO';

const NotFound = () => {
  return (
    <main className="w-full h-full">
      <SEO title="Page not found" />
      <Content contentClassName="flex flex-col items-center justify-center">
        <Text look="h1">404: Page not found</Text>
        <Link to="/" className="mt-5 text-lg underline">
          Back to home
        </Link>
      </Content>
    </main>
  );
};
export default NotFound;
