import cn from 'styles/classnames';

import Styles from './Button.module.scss';

export type ButtonVariantTypes = 'default' | 'outlined' | 'text';
export type SizeTypes = 'sm' | 'md' | 'lg';
export type ColorTypes =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'warning'
  | 'error';

export const sizeMap = {
  sm: 'px-2 py-1 text-sm',
  md: 'px-4 py-2 text-md',
  lg: 'px-8 py-4 text-lg',
};
export const variantMap = {
  default: 'rounded hover:shadow',
  outlined: 'border rounded',
  text: '',
};
export const colorMap: Record<string, Record<string, string>> = {
  default: {
    primary: cn(
      'bg-background-accent active:bg-tealPath-900 text-content-inverse disabled:bg-slate-100 disabled:text-content-primary',
      Styles.waypointButtonAccentShadow,
    ),
    secondary: cn(
      'bg-background-primary border border-border-primary active:bg-background-secondary text-content-primary disabled:bg-background-disabled disabled:text-content-disabled',
      Styles.waypointButtonSecondaryShadow,
    ),
    tertiary: cn(
      'bg-white text-content-accent  active:border active:border-border-accent disabled:border-border-disabled disabled:text-content-disabled',
      Styles.waypointButtonAccentShadow,
    ),
    warning: cn(
      'bg-background-warning text-content-primary active:bg-amber-500 disabled:bg-amber-50 disabled:text-content-disabled',
      Styles.waypointButtonWarningShadow,
    ),
    error: cn(
      'bg-background-error text-content-inverse active:bg-red-900 disabled:bg-red-50 disabled:text-content-disabled',
      Styles.waypointButtonErrorShadow,
    ),
  },
  outlined: {
    primary: cn(
      'border-border-accent text-content-accent active:bg-background-lightAccent disabled:border-border-accent disabled:text-content-accent',
      Styles.waypointButtonAccentShadow,
    ),
    warning: cn(
      'border-border-warning text-content-warning active:bg-background-lightWarning disabled:border-border-accent disabled:text-content-accent',
      Styles.waypointButtonWarningShadow,
    ),
    error: cn(
      'border-border-error text-content-error active:bg-background-lightError disabled:border-border-accent disabled:text-content-accent',
      Styles.waypointButtonErrorShadow,
    ),
  },
  text: {
    primary:
      'text-content-accent active:text-tealPath-800 disabled:text-content-disabled',
    warning:
      'text-content-warning active:text-yellow-900 disabled:text-content-disabled',
    error:
      'text-content-error active:text-red-900 disabled:text-content-disabled',
  },
};
