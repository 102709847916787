import { Link } from 'react-router-dom';

import Text from 'components/waypoint/Text';

import Logo from 'components/waypoint/Logo';
import ReleaseBadge from 'components/waypoint/ReleaseBadge';
import Search from 'components/util/Search';
import Button from 'components/waypoint/Button';
import cn from 'styles/classnames';

import { UserFeatureAccessType } from '../components/waypoint/ReleaseBadge/ReleaseBadge';

interface IHeaderProps {
  className?: string;
  hideSearch?: boolean;
}
const Header = ({ className, hideSearch }: IHeaderProps) => {
  return (
    <nav
      className={cn(
        'flex flex-row items-center px-10 py-6 bg-tealPath-900',
        className,
      )}
    >
      <div className="flex flex-row mr-auto">
        <Link to="/" className="flex flex-col">
          <Logo type="wide-light" className="h-7" />
          <Text className="self-center mt-1 text-sm text-white font-bold tracking-custom">
            HELP CENTER
          </Text>
        </Link>
        <ReleaseBadge
          releaseStage={UserFeatureAccessType.Beta}
          size="lg"
          className="ml-4"
        />
      </div>
      {!hideSearch && (
        <Search
          className="mr-8"
          size="sm"
          placeholder="Search the Help Center"
        />
      )}
      <ul className="flex flex-row items-center justify-end text-white">
        {/* {NAV_LINKS.map(({ label, path }, idx) => (
            <li key={idx} className={cn({ 'ml-5': idx > 0 })}>
              <NavLink
                to={path}
                className="font-bold text-md hover:text-content-inverse p-px"
                activeClassName="border-b"
                exact={true}
              >
                {label}
              </NavLink>
            </li>
          ))} */}
        <li className="ml-8">
          <Button
            size="sm"
            variant="text"
            className="font-bold bg-white rounded text-content-primary border-border-primary"
          >
            Log out
          </Button>
        </li>
      </ul>
    </nav>
  );
};
export default Header;
