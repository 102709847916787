import { InputValidationType } from '../InputValidation';
import { DropdownSizeType } from './Dropdown';

import Styles from './Dropdown.module.scss';

type InputValidationStyleMap = Record<InputValidationType, string>;
type SizeMap = Record<DropdownSizeType, string>;

export const inputValidationMap: InputValidationStyleMap = {
  success: Styles.waypointDropdownSuccess,
  warning: Styles.waypointDropdownWarning,
  error: Styles.waypointDropdownError,
};

export const iconSizeMap: SizeMap = {
  sm: 'text-xs',
  md: 'text-sm',
  lg: 'text-sm',
};

export const textSizeMap: SizeMap = {
  sm: 'text-sm',
  md: 'text-md',
  lg: 'text-md',
};

export const widthMap = {
  sm: 'w-76',
  md: 'w-76',
  lg: 'w-76',
};

export const dropdownSizeMap = {
  sm: 'px-2 py-1 text-sm',
  md: 'px-3 py-1',
  lg: 'px-3 py-2',
};
