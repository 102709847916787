import React, { useCallback } from 'react';

import cn from 'styles/classnames';

import Icon, { IIconProps } from 'components/waypoint/Icon';

import { ICommonProps, IInputProps } from '../util';
import Text from '../Text';
import InputValidation, { InputValidationType } from '../InputValidation';
import Popover from '../Popover';

import {
  inputValidationMap,
  iconSizeMap,
  textSizeMap,
  dropdownSizeMap,
  widthMap,
} from './styles';

import Styles from './Dropdown.module.scss';

export type DropdownSizeType = 'sm' | 'md' | 'lg';

interface IDropdownProps<T extends Record<string, string>>
  extends Omit<ICommonProps, 'id'>,
    Omit<IInputProps<keyof T | undefined>, 'onBlur'> {
  id: string;
  inputClassName?: string;
  dropdownClassName?: string;
  label?: string;
  isLabelHidden?: boolean;
  isLabelIntegrated?: boolean;
  placeholder?: string;
  hint?: string;
  size?: DropdownSizeType;
  validation?: string;
  validationType?: InputValidationType;
  disabled?: boolean;
  type?: 'text' | 'password' | 'number';
  options?: T;
  allowClear?: boolean;
  leftIcon?: IIconProps;
  searchable?: boolean;
  searchIcon?: IIconProps;
}
const Dropdown = <T extends Record<string, string>>({
  id,
  className,
  dropdownClassName,
  // input props
  name,
  value,
  onChange = () => {},
  onValueChange = () => {},
  // input component props
  inputClassName,
  label,
  isLabelHidden = false,
  isLabelIntegrated = false,
  placeholder,
  hint,
  validation,
  validationType = 'error',
  size = 'sm',
  options,
  allowClear = false,
  leftIcon,
  searchable = false,
  searchIcon,
}: IDropdownProps<T>) => {
  const inputRef = React.createRef<HTMLDivElement>();
  const dropdownRef = React.createRef<HTMLUListElement>();

  const [open, setOpen] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>('');
  const [searchFocussed, setSearchFocussed] = React.useState<boolean>(false);
  const handleChange = React.useCallback(
    (next: keyof T | undefined) => {
      setOpen(false);
      onValueChange(next);
      onChange({ target: { value: next, name } });
      if (searchable && next && options) {
        setSearch(options[next as string]);
      }
    },
    [name, onChange, onValueChange, searchable, options],
  );

  React.useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        !inputRef?.current?.contains(event.target) &&
        !dropdownRef?.current?.contains(event.target)
      ) {
        setOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, inputRef]);

  const formatResults = useCallback((item: string, keyword: string) => {
    var re = new RegExp(keyword, 'i');
    return item.replace(
      re,
      '<i class="not-italic font-normal">' + keyword + '</i>',
    );
  }, []);

  return (
    <div
      id={id}
      className={cn(
        'flex flex-col',
        Styles.waypointDropdown,
        widthMap[size],
        validationType ? inputValidationMap[validationType] : null,
        className,
      )}
    >
      {label && !isLabelHidden && !isLabelIntegrated && (
        <Text look="label" htmlFor={id}>
          {label}
        </Text>
      )}
      <Popover
        trigger="none"
        open={open || searchFocussed}
        direction="bl"
        className={cn(widthMap[size], dropdownClassName)}
        contentClassName="p-0"
        hideArrow={true}
        noPadding={true}
        content={
          <ul className="overflow-auto max-h-40" ref={dropdownRef}>
            {Object.entries(options || {})
              .filter(
                ([_, optionLabel]) =>
                  !searchable ||
                  !search ||
                  optionLabel
                    .toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase()),
              )
              .map(([optionValue, optionLabel], idx) => (
                <li
                  className={cn(
                    'text-content-primary font-normal py-1 px-2 cursor-pointer',
                    textSizeMap[size],
                    {
                      'bg-background-lightAccent': value === optionValue,
                      'hover:bg-background-tertiary': value !== optionValue,
                    },
                  )}
                  onClick={() => handleChange(optionValue)}
                  role="button"
                  key={idx}
                >
                  {searchable && search ? (
                    <span
                      className="font-bold"
                      dangerouslySetInnerHTML={{
                        __html: formatResults(optionLabel, search),
                      }}
                    ></span>
                  ) : (
                    optionLabel
                  )}
                </li>
              ))}
          </ul>
        }
      >
        <div
          className={cn(
            'border border-gray-300 rounded flex flex-row items-center justify-center bg-white w-full',
            dropdownSizeMap[size],
            {
              [Styles.waypointDropdownDefaultOpen]: open,
              [Styles.waypointDropdownSuccessOpen]:
                !open && validation && validationType === 'success',
              [Styles.waypointDropdownWarningOpen]:
                !open && validation && validationType === 'warning',
              [Styles.waypointDropdownErrorOpen]:
                !open && validation && validationType === 'error',
            },
            inputClassName,
          )}
          onClick={() => setOpen(!open)}
          ref={inputRef}
        >
          {!!leftIcon && (
            <Icon {...leftIcon} className={cn('mr-1', leftIcon.className)} />
          )}
          {searchable ? (
            <input
              className={cn(
                'flex-grow h-full focus:outline-none placeholder:text-slate-400',
                Styles.waypointInputPlaceholder,
              )}
              placeholder={placeholder}
              id={id}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onFocus={() => setSearchFocussed(true)}
              onBlur={() => setSearchFocussed(false)}
            />
          ) : (
            <span
              className={cn('flex-grow h-full select-none', textSizeMap[size])}
            >
              {isLabelIntegrated && label && (
                <span className="mr-1 font-bold">{label}:</span>
              )}
              {options ? options[value as string] || placeholder : placeholder}
            </span>
          )}

          {!!allowClear && (
            <button
              className="h-full"
              onClick={() => {
                setSearch('');
                handleChange(undefined);
              }}
            >
              <Icon
                className={cn(
                  'ml-1',
                  search === '' ? 'opacity-0' : 'opacity-1',
                  { 'mt-1': size !== 'sm', 'mt-0.5': size === 'sm' },
                  iconSizeMap[size],
                )}
                icon="times"
              />
            </button>
          )}
          <Icon
            {...searchIcon}
            className={cn(
              'ml-2 cursor-pointer',
              iconSizeMap[size],
              searchIcon?.className,
            )}
            icon={searchIcon?.icon || 'chevron-down'}
            variant={searchIcon?.variant || 'solid'}
          />
        </div>
      </Popover>
      {validation && (
        <InputValidation
          className="mb-2"
          message={validation}
          type={validationType}
        />
      )}
      {hint && (
        <Text look="hint" className="text-gray-600">
          {hint}
        </Text>
      )}
    </div>
  );
};
export default Dropdown;
