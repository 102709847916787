import React from 'react';

import cn from 'styles/classnames';

import { ICommonProps } from '../util';

import {
  ComponentType,
  getDefaultComponentMapping,
  getStyle,
  StyleType,
} from './Lookup';

export interface ITextProps extends ICommonProps {
  look?: StyleType;
  as?: ComponentType;
  onClick?: () => void;
  htmlFor?: string;
}
const Text = ({
  id,
  className,
  // Text props
  look = 'p',
  as,
  htmlFor,
  children,
  onClick,
}: React.PropsWithChildren<ITextProps>) => {
  const Component = React.useMemo(
    () => as || getDefaultComponentMapping(look),
    [as, look],
  );
  const styleClassNames = React.useMemo(() => getStyle(look), [look]);

  return (
    <Component
      id={id}
      className={cn(
        styleClassNames,
        { 'cursor-pointer': !!onClick },
        className,
      )}
      role={onClick && 'button'}
      onClick={onClick}
      htmlFor={htmlFor}
    >
      {children}
    </Component>
  );
};
export default Text;
