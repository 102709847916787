import cn from 'styles/classnames';

export type ComponentType =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'caption'
  | 'label'
  | 'span'
  | 'small'
  | 'em';
export type StyleType =
  | ComponentType
  | 'hero1'
  | 'hero2'
  | 'body'
  | 'body-sm'
  | 'body-xs'
  | 'hint'
  | 'emphasis';

export const getDefaultComponentMapping = (style: StyleType): ComponentType => {
  switch (style) {
    case 'hero1':
    case 'hero2':
      return 'h1';
    case 'body':
    case 'body-sm':
    case 'body-xs':
      return 'p';
    case 'hint':
      return 'small';
    case 'emphasis':
      return 'em';
    default:
      return style;
  }
};

export const getStyle = (style: StyleType): string => {
  const classes = ['my-0 mx-0 py-0 px-0 text-content-primary'];

  switch (style) {
    case 'hero1':
      classes.push('text-5xl md:text-6xl font-semibold');
      break;
    case 'hero2':
      classes.push('text-4xl md:text-5xl');
      break;
    case 'h1':
      classes.push('text-3xl md:text-4xl');
      break;
    case 'h2':
      classes.push('text-2xl md:text-3xl');
      break;
    case 'h3':
      classes.push('text-xl md:text-2xl font-semibold');
      break;
    case 'h4':
      classes.push('text-lg md:text-xl font-semibold');
      break;
    case 'h5':
      classes.push('text-md md:text-lg font-bold');
      break;
    case 'h6':
      classes.push('text-sm md:text-md font-bold');
      break;
    case 'p':
      classes.push('text-md');
      break;
    case 'body':
      classes.push('text-md');
      break;
    case 'body-sm':
      classes.push('text-sm');
      break;
    case 'body-xs':
      classes.push('text-xs');
      break;
    case 'caption':
      classes.push('text-sm text-content-secondary');
      break;
    case 'label':
      classes.push('text-md font-bold');
      break;
    case 'hint':
      classes.push('text-sm');
      break;
    case 'emphasis':
      classes.push('text-md font-bold');
      break;
  }

  return cn(...classes);
};
