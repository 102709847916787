import { useCallback } from 'react';

import { ICommonProps } from 'components/waypoint/util';

import Text from 'components/waypoint/Text';
import { GuideCategoryType } from 'types/resource';
import cn from 'styles/classnames';

interface IGuideTitleProps extends ICommonProps {
  category: GuideCategoryType;
}
const GuideTitle = ({ className, category }: IGuideTitleProps) => {
  const getPageTitle = useCallback(() => {
    switch (category) {
      case 'technical':
        return (
          <>
            <Text look="h1" className="mb-4">
              Developer Resources
            </Text>
            <Text className="text-md">
              APIs, SDKs and guides for developers.
            </Text>
          </>
        );
      case 'user':
        return (
          <>
            <Text look="h1" className="mb-4">
              Using Da Vinci
            </Text>
            <Text className="text-md">
              Everything you need to get started and work in Da Vinci
            </Text>
          </>
        );
      default:
        return '';
    }
  }, [category]);

  return (
    <div className={cn('absolute top-1/3 left-10', className)}>
      {getPageTitle()}
    </div>
  );
};
export default GuideTitle;
