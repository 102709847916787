export type SyntheticChangeEventType<T> = {
  target: {
    name?: string;
    value: T;
  };
};

export type SyntheticBlurEventType = {
  target: {
    name?: string;
  };
};

export interface ICommonProps {
  id?: string;
  className?: string;
}

export interface IInputProps<T> {
  name?: string;
  label?: string;
  error?: string;
  value?: T;
  /** onChange with event info, primarily for formik */
  onChange?: (e: SyntheticChangeEventType<T>) => void;
  /** onChange with just the value */
  onValueChange?: (value: T) => void;
  onBlur?: (e: SyntheticBlurEventType) => void;
  disabled?: boolean;
}

export type SizeType = 'sm' | 'md' | 'lg';

export type DirectionType =
  | 't'
  | 'b'
  | 'l'
  | 'r'
  | 'tr'
  | 'br'
  | 'tl'
  | 'bl'
  | 'lt'
  | 'lb'
  | 'rt'
  | 'rb';

export const directionToPlacement = (direction: DirectionType) => {
  switch (direction) {
    case 't':
      return 'top';
    case 'b':
      return 'bottom';
    case 'l':
      return 'left';
    case 'r':
      return 'right';
    case 'tr':
      return 'topRight';
    case 'br':
      return 'bottomRight';
    case 'tl':
      return 'topLeft';
    case 'bl':
      return 'bottomLeft';
    case 'lt':
      return 'leftTop';
    case 'lb':
      return 'leftBottom';
    case 'rt':
      return 'rightTop';
    case 'rb':
      return 'rightBottom';
  }
};
