export const getGuideMetaDescription = (
  guideTitle?: string,
  guideCategory?: string,
) => {
  if (guideTitle && guideCategory === 'Using Coherent Path') {
    switch (guideTitle) {
      case 'Campaigns 101':
        return 'New to Da Vinci app? You’ve come to the right place. In this quick-start guide, we’ll walk you through the basics of one of the most important elements of the Coherent Path app—Campaigns';
      case 'Accessing your Account':
        return `Learn how to access your Da Vinci account with this easy to follow guide`;
      case 'Campaigns: Getting Started':
        return `Learn how to get started creating and deploying your personalized email messages to your audience on the Campaigns page of the Coherent Path app`;
      case 'Creating & Duplicating':
        return `Campaigns are highly customizable configurations that create and deploy personalized email messages to your audience. If you’re creating a new campaign or want to duplicate an existing one, then read this guide`;
      case 'Navigating: Campaigns Tab':
        return `Learn how your campaigns are performing. This guide covers everything you need to effectively track your campaign performance and maneuver configurations within the Analytics page`;
      case 'Navigating: Creatives Tab':
        return `Learn how your creatives are performing within campaigns. This guide covers everything you need to effectively track the performance of your creatives within the Analytics page`;
      case 'Navigating: Experiments Tab':
        return `Find out which of your email marketing experiments are working and which need a closer look. This guide covers everything you need to effectively track the performance of your experiments within the Analytics page`;
      case 'Understanding Key Metrics':
        return `Learn what different key metrics mean and what they tell you about your campaigns, creatives and experiments`;
    }
  } else if (guideTitle && guideCategory === 'Developer Resources') {
    switch (guideTitle) {
      case 'Getting started':
        return `Learn how to get started using Da Vinci Signal to record user event data from any website or application`;
      case 'Core resources':
        return `The Da Vinci Signal API exposes a set of core resources that let you create a variety of events in a RESTful manner. All requests require authentication`;
      case 'Javascript SDK':
        return `The Da Vinci Signal Javascript SDK lets you interact with the Signal API in an easy-to-use manner on your website. The JavaScript SDK will let you…`;
    }
  } else if (guideCategory) {
    return 'Welcome to Da Vinci —  Here you’ll find everything you need to get started and work in Da Vinci';
  }
  return '';
};
