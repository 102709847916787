import { useCallback } from 'react';

import { NavHashLink } from 'react-router-hash-link';

import { ICommonProps } from 'components/waypoint/util';

import Text from 'components/waypoint/Text';
import cn from 'styles/classnames';

import Styles from './GuideSideNav.module.scss';

interface IGuideContentProps extends ICommonProps {
  navigationItems: NodeListOf<HTMLHeadingElement>;
  className?: string;
}

const GuideSideNav = ({ navigationItems, className }: IGuideContentProps) => {
  const getNavigationText = useCallback(() => {
    const navItems: { element: string; elementText: string }[] = [];
    if (navigationItems) {
      navigationItems.forEach((el, index) => {
        navItems.push({ element: el.localName, elementText: el.innerText });
        el.id = 'heading-' + index;
      });
    }
    return navItems;
  }, [navigationItems]);

  return (
    <div className={cn('ml-6 xl:ml-10', className)}>
      <div
        className={cn(
          'p-4 mt-14 sticky top-8 w-54 xl:w-70',
          Styles.sideNavContainer,
        )}
      >
        <div className="border-b border-border-primary pb-2">
          <Text className="text-sm font-extrabold tracking-custom">
            IN THIS GUIDE
          </Text>
        </div>
        <div className="flex flex-col mt-2">
          {getNavigationText().map(({ element, elementText }, idx) => (
            <NavHashLink
              to={'#heading-' + idx}
              id={'nav-heading-' + idx}
              key={idx}
              className={cn('hover:text-content-accent mb-2', {
                'ml-4': element === 'h4',
              })}
              activeStyle={{
                color: '#405aa9',
              }}
            >
              {elementText}
            </NavHashLink>
          ))}
        </div>
      </div>
    </div>
  );
};
export default GuideSideNav;
