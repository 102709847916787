import { InputValidationType } from './InputValidation';
type InputValidationStyleMap = Record<InputValidationType, string>;

export const inputValidationColorMap: InputValidationStyleMap = {
  success: 'bg-background-success',
  warning: 'bg-background-warning',
  error: 'bg-background-error',
};

export const inputValidationArrowMap: InputValidationStyleMap = {
  success: 'text-content-success',
  warning: 'text-amber-400',
  error: 'text-content-error',
};

export const inputValidationTextColorMap: InputValidationStyleMap = {
  success: 'text-white',
  warning: 'text-content-primary',
  error: 'text-white',
};

export const inputValidationIconMap: InputValidationStyleMap = {
  success: 'check',
  warning: 'exclamation-triangle',
  error: 'exclamation-circle',
};
