import { useMemo } from 'react';

import { useHistory } from 'react-router';

import { useResource } from 'components/providers/Resource';
import Dropdown from 'components/waypoint/Dropdown';

type ISearchProps = {
  className?: string;
  dropdownClassName?: string;
  placeholder?: string;
  size?: 'sm' | 'lg';
};
const Search = ({
  className,
  dropdownClassName,
  size = 'lg',
  placeholder = '',
}: ISearchProps) => {
  const { guides, updates } = useResource();
  const history = useHistory();

  const options = useMemo(() => {
    const options: Record<string, string> = {};

    Object.entries(guides).forEach(([category, guides]) =>
      guides.forEach(({ guides }) =>
        guides?.forEach(
          ({ id, title }) => (options[`/guides/${category}/${id}`] = title),
        ),
      ),
    );

    updates.forEach(({ id, title }) => (options[`/updates/${id}`] = title));
    return options;
  }, [guides, updates]);

  return (
    <Dropdown
      id="documentation-search-input"
      placeholder={placeholder}
      className={className}
      dropdownClassName={dropdownClassName}
      options={options}
      onValueChange={(next) => history.push(next as string)}
      size={size}
      searchable={true}
      allowClear={true}
      searchIcon={{ icon: 'search', variant: 'thin' }}
    />
  );
};
export default Search;
