import { useMemo } from 'react';

import { Link } from 'react-router-dom';

import { useResource } from 'components/providers/Resource';
import { ICommonProps } from 'components/waypoint/util';
import Content from 'layout/Content';
import { GuideCategoryType } from 'types/resource';
import Text from 'components/waypoint/Text';

interface IOverviewProps extends ICommonProps {
  category: GuideCategoryType;
}
const Overview = ({ className, category }: IOverviewProps) => {
  const { getGuideCategory } = useResource();
  const sections = useMemo(
    () => getGuideCategory(category),
    [category, getGuideCategory],
  );
  return (
    <Content className={className} contentClassName="px-10 pt-14 pb-10">
      {sections.map(({ title, guides, guideSubSection }, idx) => (
        <section key={idx} className="pb-10">
          <Text look="h2" className="mb-8 leading-7.5 text-3xl">
            {title}
          </Text>
          <ul className="pl-4">
            {guideSubSection
              ? guideSubSection.map(({ id, title, guides }) => (
                  <>
                    <Text
                      key={id}
                      className="mb-8 leading-7.5 text-md font-semibold"
                    >
                      {title}
                    </Text>
                    <ul className="pl-4 pb-4">
                      {guides.map(({ id, title }, idx) => (
                        <li
                          key={idx}
                          className="mb-4 list-disc text-content-secondary"
                        >
                          <Link
                            to={`/guides/${category}/${id}`}
                            className="text-md text-content-primary hover:underline hover:text-content-primary"
                          >
                            {title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </>
                ))
              : guides.map(({ id, title }, idx) => (
                  <li
                    key={idx}
                    className="mb-4 list-disc text-content-secondary"
                  >
                    <Link
                      to={`/guides/${category}/${id}`}
                      className="text-md text-content-primary hover:underline hover:text-content-primary"
                    >
                      {title}
                    </Link>
                  </li>
                ))}
          </ul>
        </section>
      ))}
    </Content>
  );
};
export default Overview;
