import React, { ReactNode } from 'react';

import cn from 'styles/classnames';
import Icon from 'components/waypoint/Icon';

import { ICommonProps } from '../util';

export interface ITimelineProps extends ICommonProps {
  items: ReactNode[];
}
const Timeline = ({ id, className, items = [] }: ITimelineProps) => {
  return (
    <ul id={id} className={cn('', className)}>
      {items.map((item, idx) => (
        <li key={idx} className="flex flex-row">
          <div className="flex flex-col items-center mr-5">
            <div
              className={cn('w-px h-15 bg-background-disabled', {
                invisible: idx === 0,
              })}
            ></div>
            <Icon icon="circle" variant="solid" className="text-gray-300" />
            <div
              className={cn('flex-grow w-px bg-background-disabled', {
                invisible: idx === items.length - 1,
              })}
            ></div>
          </div>
          <div className="flex-grow">{item}</div>
        </li>
      ))}
    </ul>
  );
};
export default Timeline;
