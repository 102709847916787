import { ReactNode, useState } from 'react';

import cn from 'styles/classnames';
import Button from 'components/waypoint/Button';
import Text from 'components/waypoint/Text';
import Icon from 'components/waypoint/Icon';

import { ICommonProps } from '../util';

export interface ITreeProps extends ICommonProps {
  items: {
    id: string;
    label: ReactNode;
    onClick?: () => void;
    items: {
      title?: string;
      label: ReactNode;
      hide?: boolean;
      onClick?: () => void;
    }[];
  }[];
}

const Tree = ({ id, className, items = [] }: ITreeProps) => {
  const [open, setOpen] = useState<string>('overview');
  const [subGuideOpen, setSubGuideOpen] = useState<string[]>([]);

  return (
    <ul id={id} className={cn('sticky top-8', className)}>
      {items.map(({ id, label, items, onClick }, idx) => (
        <li key={idx} className="flex flex-col mb-6">
          <div className="flex flex-row items-center">
            <Button
              size="sm"
              variant="text"
              className={cn(
                'text-content-primary py-0',
                open.includes(id) ? 'font-bold' : 'font-normal',
              )}
              onClick={() => {
                setOpen(id);
                if (onClick) {
                  onClick();
                }
              }}
            >
              <Icon
                icon={open.includes(id) ? 'angle-down' : 'angle-right'}
                variant={open.includes(id) ? 'solid' : 'thin'}
                className={cn(
                  'mr-2 text-lg',
                  open.includes(id) ? 'font-bold' : 'font-normal',
                )}
              />
              <Text className="text-md">{label}</Text>
            </Button>
          </div>
          {open.includes(id) && (
            <ul className="pl-7 list-none">
              {items.map(({ title, hide, label, onClick }, idx) => (
                <>
                  {!(hide === true) && title && (
                    <div className="flex flex-row items-center my-2">
                      <Button
                        size="sm"
                        variant="text"
                        className={cn(
                          'text-content-primary py-0',
                          subGuideOpen.includes(title)
                            ? 'font-bold'
                            : 'font-normal',
                        )}
                        onClick={() => {
                          if (subGuideOpen.includes(title)) {
                            setSubGuideOpen(
                              subGuideOpen.filter((next) => next !== title),
                            );
                          } else {
                            setSubGuideOpen([...subGuideOpen, title]);
                          }
                          if (onClick) {
                            onClick();
                          }
                        }}
                      >
                        <Icon
                          icon={
                            subGuideOpen.includes(title)
                              ? 'angle-down'
                              : 'angle-right'
                          }
                          variant={open.includes(title) ? 'solid' : 'thin'}
                          className={cn(
                            'mr-2 text-lg',
                            subGuideOpen.includes(title)
                              ? 'font-bold'
                              : 'font-normal',
                          )}
                        />
                        <Text className="text-md">{title} </Text>
                      </Button>
                    </div>
                  )}
                  {open.includes(id) && !title ? (
                    <li
                      key={idx}
                      className="my-2 text-sm last: mb-0"
                      onClick={onClick}
                    >
                      {label}
                    </li>
                  ) : (
                    subGuideOpen.includes(title ? title : '') && (
                      <li
                        key={idx}
                        className="my-2 text-sm last: mb-0"
                        onClick={onClick}
                      >
                        {label}
                      </li>
                    )
                  )}
                </>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};
export default Tree;
