import React from 'react';

import cn from 'styles/classnames';
import Icon, { IconName, IIconProps } from 'components/waypoint/Icon';

import Text from '../Text';

import {
  inputValidationColorMap,
  inputValidationTextColorMap,
  inputValidationArrowMap,
  inputValidationIconMap,
} from './styles';
import { ICommonProps } from '../util';

export type InputValidationType = 'success' | 'warning' | 'error';
export interface IInputValidationProps extends ICommonProps {
  type: InputValidationType;
  message: string;
  icon?: IIconProps;
}
const InputValidation = ({
  id,
  className,
  type,
  icon,
  message,
}: IInputValidationProps) => {
  return (
    <div id={id} className={cn('flex flex-col my-2', className)}>
      <Icon
        className={cn('ml-2 text-3xs', inputValidationArrowMap[type])}
        icon="triangle"
        variant="solid"
      />
      <div
        className={cn(
          'px-2 py-2 rounded flex items-center',
          inputValidationColorMap[type],
        )}
      >
        <Icon
          icon={inputValidationIconMap[type] as IconName}
          className={cn('text-sm mr-1.5 ', inputValidationTextColorMap[type])}
        />
        <Text
          as="small"
          className={cn('text-sm', inputValidationTextColorMap[type])}
        >
          {message}
        </Text>
      </div>
    </div>
  );
};
export default InputValidation;
