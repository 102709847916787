import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import Content from 'layout/Content';
import SEO from 'layout/SEO';
import { useResource } from 'components/providers/Resource';

import Application from 'layout/Application';
import Text from 'components/waypoint/Text';
import Markdown from 'components/util/Markdown';

const Update = () => {
  const { updateId } = useParams<{ updateId?: string }>();
  const { getUpdate, getUpdateContent } = useResource();

  const [content, setContent] = useState<string>();

  const update = useMemo(
    () => (updateId ? getUpdate(updateId) : undefined),
    [getUpdate, updateId],
  );

  useEffect(() => {
    if (update && updateId) {
      getUpdateContent(updateId).then(setContent);
    }
  }, [updateId, update, getUpdateContent]);

  return (
    <Application>
      <SEO title={update?.title} />
      <Content contentClassName="py-10 px-5 h-full">
        <Text look="h1" className="pb-10">
          {update?.title}
        </Text>
        <Markdown content={content || ''} />
      </Content>
    </Application>
  );
};
export default Update;
