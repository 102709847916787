import React from 'react';
import { Popover as AntPopover } from 'antd';

import cn from 'styles/classnames';

import Styles from './Popover.module.scss';

import {
  directionToPlacement,
  DirectionType,
  ICommonProps,
  SizeType,
} from '../util';

export interface IPopoverProps extends ICommonProps {
  /** classname of trigger children wrapper */
  triggerClassName?: string;
  contentClassName?: string;
  size?: Omit<SizeType, 'md'>;
  direction?: DirectionType;
  content?: React.ReactNode;
  trigger?: 'click' | 'focus' | 'hover' | 'none';
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  hideArrow?: boolean;
  noPadding?: boolean;
  avoidOverflow?: boolean;
  containInParent?: boolean;
}
const Popover = ({
  id,
  className,
  triggerClassName,
  contentClassName,
  size = 'sm',
  direction = 't',
  content,
  trigger = 'hover',
  open,
  onOpenChange = () => {},
  hideArrow = false,
  noPadding = false,
  avoidOverflow = true,
  containInParent = false,
  children,
}: React.PropsWithChildren<IPopoverProps>) => {
  return (
    <AntPopover
      id={id}
      className={triggerClassName}
      overlayClassName={cn(
        Styles.waypointPopover,
        { [Styles.waypointNoArrowPopover]: hideArrow },
        { [Styles.waypointNoPaddingPopover]: noPadding },
        className,
      )}
      content={
        <div
          className={cn(
            {
              'p-2': size === 'sm',
              'p-4': size === 'lg',
            },
            contentClassName,
          )}
        >
          {content}
        </div>
      }
      trigger={trigger === 'none' ? [] : trigger}
      placement={directionToPlacement(direction)}
      autoAdjustOverflow={avoidOverflow}
      visible={open}
      onVisibleChange={onOpenChange}
      getPopupContainer={
        containInParent
          ? (trigger) => trigger.parentElement || document.body
          : undefined
      }
    >
      {children}
    </AntPopover>
  );
};

export default Popover;
