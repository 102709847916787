import React, { useMemo } from 'react';

import Text from 'components/waypoint/Text';

import cn from 'styles/classnames';

import { ICommonProps } from '../util';

type BadgeSizeType = 'md' | 'lg';

export enum UserFeatureAccessType {
  Release = 'RELEASE',
  Early = 'EARLY',
  Beta = 'BETA',
  Alpha = 'ALPHA',
  Experimental = 'EXPERIMENTAL',
}

export interface IReleaseBadgeProps extends ICommonProps {
  releaseStage: UserFeatureAccessType;
  size?: BadgeSizeType;
}

const ReleaseBadge = ({
  className,
  releaseStage,
  size = 'md',
}: IReleaseBadgeProps) => {
  const releaseBadgeText = useMemo<string>(() => {
    switch (releaseStage) {
      case UserFeatureAccessType.Early:
        return 'EARLY';
      case UserFeatureAccessType.Alpha:
        return 'ALPHA';
      case UserFeatureAccessType.Beta:
        return 'BETA';
      case UserFeatureAccessType.Experimental:
        return 'EXPERIMENTAL';
      default:
        return '';
    }
  }, [releaseStage]);

  const badgeBgStyles = useMemo<string>(() => {
    switch (releaseStage) {
      case UserFeatureAccessType.Early:
        return 'bg-orange-100 text-orange-700';
      case UserFeatureAccessType.Alpha:
        return 'bg-pink-100 text-pink-700';
      case UserFeatureAccessType.Beta:
        return 'bg-background-warning text-content-primary';
      case UserFeatureAccessType.Experimental:
        return 'bg-purple-100 text-purple-700';
      default:
        return '';
    }
  }, [releaseStage]);

  const badgeTextStyles = useMemo<string>(() => {
    switch (releaseStage) {
      case UserFeatureAccessType.Early:
        return 'text-orange-700';
      case UserFeatureAccessType.Alpha:
        return 'text-pink-700';
      case UserFeatureAccessType.Beta:
        return 'text-content-primary';
      case UserFeatureAccessType.Experimental:
        return 'text-purple-700';
      default:
        return '';
    }
  }, [releaseStage]);
  return (
    <Text
      className={cn(
        'text-2xs font-bold w-min rounded-sm ml-1 self-center',
        badgeBgStyles,
        badgeTextStyles,
        {
          'px-1 py-1': size === 'md',
          'px-2 py-1 text-xs': size === 'lg',
        },
        className,
      )}
    >
      {releaseBadgeText}
    </Text>
  );
};
export default ReleaseBadge;
