import React from 'react';
import { Tooltip as AntTooltip } from 'antd';

import cn from 'styles/classnames';
import Icon, { IconName, IIconProps } from 'components/waypoint/Icon';

import Text from '../Text';

import {
  tooltipTextColorMap,
  tooltipSubTextColorMap,
  tooltipIconMap,
} from './styles';
import Styles from './Tooltip.module.scss';
import { directionToPlacement, DirectionType, ICommonProps } from '../util';

export type TooltipType =
  | 'success'
  | 'warning'
  | 'error'
  | 'information'
  | 'accent'
  | 'primary'
  | 'secondary'
  | 'tertiary';
export type TooltipPriorityType = 'low' | 'high';

export interface ITooltipProps extends ICommonProps {
  contentClassName?: string;
  leftIcon?: IIconProps;
  rightIcon?: IIconProps;
  label: string;
  helperText?: string;
  type?: TooltipType;
  priority?: TooltipPriorityType;
  direction?: DirectionType;
  trigger?: 'hover' | 'click' | 'focus';
  containInParent?: boolean;
}
const Tooltip = ({
  id,
  className,
  // tooltip props
  contentClassName,
  leftIcon,
  rightIcon,
  label,
  helperText,
  type = 'primary',
  priority = 'low',
  direction = 'b',
  trigger = 'hover',
  containInParent = false,
  children,
}: React.PropsWithChildren<ITooltipProps>) => {
  return (
    <AntTooltip
      id={id}
      overlayClassName={cn(
        Styles.waypointTooltip,
        {
          [Styles.waypointLowSuccessTooltip]:
            priority === 'low' && type === 'success',
          [Styles.waypointHighSuccessTooltip]:
            priority === 'high' && type === 'success',
          [Styles.waypointLowWarningTooltip]:
            priority === 'low' && type === 'warning',
          [Styles.waypointHighWarningTooltip]:
            priority === 'high' && type === 'warning',
          [Styles.waypointLowErrorTooltip]:
            priority === 'low' && type === 'error',
          [Styles.waypointHighErrorTooltip]:
            priority === 'high' && type === 'error',
          [Styles.waypointLowInformationTooltip]:
            priority === 'low' && type === 'information',
          [Styles.waypointHighInformationTooltip]:
            priority === 'high' && type === 'information',
          [Styles.waypointAccentTooltip]: type === 'accent',
          [Styles.waypointGenericTooltip]: type === 'primary',
          [Styles.waypointSecondaryTooltip]: type === 'secondary',
          [Styles.waypointTertiaryTooltip]: type === 'tertiary',
        },
        className,
      )}
      placement={directionToPlacement(direction)}
      trigger={trigger}
      autoAdjustOverflow={true}
      overlay={
        <div
          className={cn(
            'flex flex-row items-center justify-center bg-transparent',
            tooltipTextColorMap[priority][type],
            contentClassName,
          )}
        >
          {!!leftIcon && (
            <Icon
              {...leftIcon}
              icon={tooltipIconMap[type] as IconName}
              className={cn('mr-2', leftIcon.className)}
              spin={type === 'accent'}
            />
          )}
          <Text look="hint" className={tooltipTextColorMap[priority][type]}>
            {label}
          </Text>
          {!!helperText && (
            <Text
              look="hint"
              className={cn('ml-1', tooltipSubTextColorMap[priority][type])}
            >
              ({helperText})
            </Text>
          )}
          {!!rightIcon && (
            <Icon {...rightIcon} className={cn('ml-2', rightIcon.className)} />
          )}
        </div>
      }
      getPopupContainer={
        containInParent
          ? (trigger) => trigger.parentElement || document.body
          : undefined
      }
    >
      {children}
    </AntTooltip>
  );
};
export default Tooltip;
